import { v4 as uuidv4 } from "uuid";
import React, { Suspense, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import NewDashboardLayout from "@/layout/NewDashboardLayout";

import useFloorPlan from "@/hooks/useFloorPlan";
import FloorplanTLDrawCanvas from "@/components/FloorplanTLDraw";
import { useEvent } from "@/hooks/useEvent";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import DefaultView from "@/components/DefaultView";
import { getEvent } from "@/axios/get-request";
import { FloorPlanObjectList } from "@/data/types";
import { createFloorPlanRequest } from "@/axios/post-request";
import Spinner from "@/components/Spinner";
import "@/styles/floor_plan_new.scss";

const CreatePlanModal = React.lazy(() => import("@/components/modals/CreatePlanModal"));

function addFloorPlanHandler(name: string, id: string, sidebar: boolean) {
    const stageWidth = window.innerWidth;
    const stageHeight = window.innerHeight;
    const dimensionElementSizes = {
        height: 400,
        width: 1000,
    };
    const divider = sidebar ? 10 : 5;
    const rectX = (stageWidth - dimensionElementSizes.width) / divider;
    const rectY = (stageHeight - dimensionElementSizes.height) / 10;
    const floorPlanObjectId = `${FloorPlanObjectList.regular__dimensions}__${id}`;

    return {
        id,
        floor_plan_name: name ? name : "New Floor Plan",
        floor_plan_icon: "",
        activeFloorPlanElementId: null,
        stage: [
            {
                ...dimensionElementSizes,
                fill: "white",
                id: floorPlanObjectId,
                x: rectX,
                y: rectY,
                type: FloorPlanObjectList.regular__dimensions,
            },
        ],
    };
}
export default function FloorPlanTLDraw() {
    const [modal, setModal] = useState(false);
    const { sidebar, toggleSidebar, floorPlanStage, setFloorPlanStage, addDefaultFloorPlanHandler } = useFloorPlan();
    const { selectedEventId, activeCeremonyId } = useEvent();
    const [fullView, setFullView] = useState(false);
    const [loading, setLoading] = useState(false);
    const mobileDevice = useMediaQuery("(max-width:768px)");
    const { data, status, refetch } = useQuery({
        queryKey: [`event_${selectedEventId}`],
        queryFn: () => getEvent(selectedEventId),
    });

    const eventData = data?.data;

    function fullViewHandler(value: boolean) {
        setFullView(value);
    }

    useEffect(() => {
        if (sidebar) {
            toggleSidebar();
        }
    }, []);

    useEffect(() => {
        if (status === "success" && eventData?.result) {
            if (!eventData?.result?.floor_plan || (Array.isArray(eventData?.result?.floor_plan) && eventData?.result?.floor_plan?.length === 0)) {
                setFloorPlanStage([]);
            }
        }
    }, [status]);

    function onCloseModal() {
        setModal(false);
    }

    async function addNewFloorPlanHandler() {
        try {
            const floorPlanStageCount = floorPlanStage?.length > 0 ? floorPlanStage.length + 1 : "";
            const newFloorplanName = `New Floor plan ${floorPlanStageCount}`;
            const floorplanId = uuidv4();
            const floorPlanDetails = addFloorPlanHandler(newFloorplanName, floorplanId, sidebar);
            setLoading(true);
            await createFloorPlanRequest(selectedEventId, {
                ...floorPlanDetails,
                event_id: selectedEventId,
                ceremony_id: activeCeremonyId,
            });
            addDefaultFloorPlanHandler(newFloorplanName, floorplanId);
            setLoading(false);
            refetch();
        } catch (error) {
            setLoading(false);
            console.log("error", error);
        }
    }

    return (
        <>
            <Suspense>{modal && <CreatePlanModal title="Ceremony" onClose={onCloseModal} />}</Suspense>
            <>
                {fullView ? (
                    <section className="draw_floor_plan">
                        <div className="drawing_board">
                            <div className="board_content">
                                <FloorplanTLDrawCanvas fullViewHandler={fullViewHandler} />
                            </div>
                        </div>
                    </section>
                ) : (
                    <NewDashboardLayout title="Floor Plan" description="Manage your ceremonies' floor plans.">
                        {mobileDevice ? (
                            <DefaultView title="Floor plan" description="For the best experience, view the floor plan on a tablet or laptop." />
                        ) : status === "loading" ? (
                            <Spinner />
                        ) : eventData?.result?.floor_plan &&
                          Array.isArray(eventData?.result?.floor_plan) &&
                          eventData?.result?.floor_plan?.length > 0 ? (
                                <section className="draw_floor_plan">
                                    <div className="drawing_board">
                                        <div className="board_content">
                                            <FloorplanTLDrawCanvas fullViewHandler={fullViewHandler} />
                                        </div>
                                    </div>
                                </section>
                            ) : (
                                <DefaultView
                                    title="You have no Floor Plan yet :("
                                    description="Draw your floor plan from scratch using our custom objects and elements and your creativity."
                                    buttonText="Create Floor Plan"
                                    isLoading={loading}
                                    onClickHandler={addNewFloorPlanHandler}
                                />
                            )}
                    </NewDashboardLayout>
                )}
            </>
        </>
    );
}
