/* eslint-disable */
import { Geometry2d, RecordProps, Rectangle2d, ShapeUtil, SVGContainer, T, TLBaseShape, TLResizeInfo, resizeBox } from "tldraw";

type ICustomShape = TLBaseShape<
	"rectangle_table_3",
	{
		w: number;
		h: number;
		color: string;
	}
>;

// [2]
export class RectangleTable3Element extends ShapeUtil<ICustomShape> {
	static override type = "rectangle_table_3" as const;
	static override props: RecordProps<ICustomShape> = {
		w: T.number,
		h: T.number,
		color: T.string,
	};

	// [b]
	getDefaultProps(): ICustomShape["props"] {
		return {
			w: 19,
			h: 15,
			color: "#D9D9D9",
		};
	}

	// [c]
	override canEdit() {
		return true;
	}
	override canResize() {
		return true;
	}
	override isAspectRatioLocked() {
		return false;
	}

	// [d]
	getGeometry(shape: ICustomShape): Geometry2d {
		return new Rectangle2d({
			width: shape.props.w,
			height: shape.props.h,
			isFilled: true,
		});
	}

	// [e]
	override onResize(shape: ICustomShape, info: TLResizeInfo<any>) {
		return resizeBox(shape, info);
	}

	// [f]
	component(shape: ICustomShape) {
		return (
			<SVGContainer>
				<svg width={shape.props.w} height={shape.props.h} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M2.1665 1.66553L2.1665 3.99853C2.1665 4.45886 2.53967 4.83203 3 4.83203L5.333 4.83203C5.79333 4.83203 6.1665 4.45886 6.1665 3.99853V1.66553C6.1665 1.2052 5.79333 0.832031 5.333 0.832031L3 0.832031C2.53967 0.832031 2.1665 1.2052 2.1665 1.66553Z"
						fill="#D9D9D9"
					/>
					<path
						d="M7 1.66553L7 3.99853C7 4.45886 7.37317 4.83203 7.8335 4.83203L10.1665 4.83203C10.6268 4.83203 11 4.45886 11 3.99853V1.66553C11 1.2052 10.6268 0.832031 10.1665 0.832031L7.8335 0.832031C7.37317 0.832031 7 1.2052 7 1.66553Z"
						fill="#D9D9D9"
					/>
					<path
						d="M11.8335 1.66553V3.99853C11.8335 4.45886 12.2067 4.83203 12.667 4.83203L15 4.83203C15.4603 4.83203 15.8335 4.45886 15.8335 3.99853V1.66553C15.8335 1.2052 15.4603 0.832031 15 0.832031L12.667 0.832031C12.2067 0.832031 11.8335 1.2052 11.8335 1.66553Z"
						fill="#D9D9D9"
					/>
					<path
						d="M0.244043 12.832L0.244043 6.83203C0.244043 6.33498 0.646987 5.93203 1.14404 5.93203L17.144 5.93203C17.6411 5.93203 18.044 6.33498 18.044 6.83203V12.832C18.044 13.3291 17.6411 13.732 17.144 13.732L1.14404 13.732C0.646987 13.732 0.244043 13.3291 0.244043 12.832Z"
						fill="#D9D9D9"
						stroke="#404040"
						strokeWidth="0.2"
					/>
				</svg>
			</SVGContainer>
		);
	}

	// [g]
	indicator() {
		return;
	}
}
