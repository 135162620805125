import { Suspense, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import useUser from "@/hooks/useUser";
import FoldedCalendarIcon from "@/assets/icon/FoldedCalendarIcon";
import PlusIcon from "@/assets/icon/PlusIcon";
import ThreedotIcon from "@/assets/icon/ThreedotIcon";
import SiteDropdown from "@/components/Dropdown";
import NewDashboardLayout from "@/layout/NewDashboardLayout";
import CircleDashedIcon from "@/assets/icon/CircleDashedIcon";
import Spinner from "@/components/Spinner";
import { eventCardStyle, eventImage } from "@/utils/event_card";
import { useEvent } from "@/hooks/useEvent";
import { updateTourGuide } from "@/axios/post-request";
import { EventType, UserList } from "@/types";
import ShieldIcon from "@/assets/icon/ShieldIcon";
import { getAllVendors, getEvents } from "@/axios/get-request";
import { CollaboratorPermissionList, EventFilterType } from "@/data/types";
import FilterDropdown from "@/components/FilterDropdown";
import DeleteUserEventModal from "@/components/modals/DeleteUserEventModal";
import CreateNewEventModal from "@/components/modals/CreateNewEventModal";
import HomepageWelcomeModal from "@/components/modals/HomepageWelcomeModal";
import SelectUserTypeModal from "@/components/modals/SelectUserTypeModal";
import "@/styles/event_overview.scss";

const filterArray = [
    { text: "Newest", key: "newest" },
    { text: "Name (A - Z)", key: "asc" },
    { text: "Name (Z - A)", key: "desc" },
];

const EventFilterGroup = {
    newest: "Newest",
    asc: "Name (A - Z)",
    desc: "Name (Z - A)",
};

enum ModalList {
    create_new_event_modal = "create_new_event_modal",
    delete_event_modal = "delete_event_modal",
    welcome_modal = "welcome_modal",
    select_user_type_modal = "select_user_type_modal",
}
type ModalType = keyof typeof ModalList | null;

export default function Homepage() {
    const { data: userData } = useUser();
    const [modal, setModal] = useState<ModalType>(null);
    const { selectedEventId, setSelectedEventId, setActiveCeremonyId } = useEvent();
    const navigate = useNavigate();
    const [filter, setFilter] = useState<EventFilterType>("desc");
    const { status, data } = useQuery({
        queryKey: [`get_all_events_${filter}`],
        queryFn: () => getEvents(onSuccessHandler, filter),
    });
    const [eventDetails, setEventDetails] = useState({
        id: "",
        name: "",
    });
    const { data: __vendorData } = useQuery({
        queryKey: ["all_event_vendors"],
        queryFn: () => getAllVendors(),
    });

    const userDetails = userData?.result ? userData?.result : null;

    const vendorData = __vendorData?.data;

    function onSuccessHandler(data: { result: EventType[]; status: boolean }) {
        if (!data.status) {
            setSelectedEventId("");
        }
        if (!selectedEventId && data?.result && Array.isArray(data?.result) && data?.result?.length > 0) {
            setSelectedEventId(data.result[0]?.id);
        }
    }

    function onFilterEventHandler(filterValue: EventFilterType) {
        setFilter(filterValue);
    }

    function onClickEvent(eventId: string) {
        setSelectedEventId(eventId);
        if (data?.data?.result) {
            const selectedCeremonyId = data?.data?.result?.filter((item) => item.id === eventId)[0]?.ceremonies[0]?.id;
            if (selectedCeremonyId) {
                setActiveCeremonyId(selectedCeremonyId);
            }
        }
        return navigate(`/dashboard/event-overview?event-id=${eventId}`);
    }

    useEffect(() => {
        if (userData?.result && !userDetails?.tour_guide?.homepage && userData.result.user_type === UserList.planner) {
            setModal(ModalList.welcome_modal);
        }

        if (userData && !userDetails?.user_type) {
            setModal(ModalList.select_user_type_modal);
        }
    }, [userData]);

    const userEvents = status === "success" && data?.data?.result ? data?.data?.result : null;

    async function onCloseOverviewWelcomeModal() {
        setModal(null);
        await updateTourGuide({
            homepage: true,
        });
    }

    function showCreateEventModalHandler() {
        setModal(ModalList.create_new_event_modal);
    }

    function showDeleteEventModalHandler(id: string, name: string) {
        setModal(ModalList.delete_event_modal);
        setEventDetails({
            id,
            name,
        });
    }

    function onCloseModalHandler() {
        setModal(null);
    }

    let name = "";

    if (userDetails?.user_type === UserList.planner) {
        name = userDetails?.business_name ? userDetails?.business_name : "Admin";
    } else {
        name = userDetails?.name ? userDetails?.name : userDetails?.display_name ? userDetails?.display_name : "";
    }
    const adminGreetings = `Hi ${name}`;

    return (
        <>
            <Suspense>
                {modal === ModalList.welcome_modal && <HomepageWelcomeModal show={true} onHide={onCloseOverviewWelcomeModal} />}
                {modal === ModalList.create_new_event_modal && <CreateNewEventModal onClose={onCloseModalHandler} />}
                {modal === ModalList.delete_event_modal && (
                    <DeleteUserEventModal filter={filter} eventDetails={eventDetails} onClose={onCloseModalHandler} />
                )}
                {modal === ModalList.select_user_type_modal &&
                    userDetails?.id &&
                    userDetails?.user_type !== UserList.celebrant &&
                    userDetails?.user_type !== UserList.planner && (
                    <SelectUserTypeModal userId={userDetails?.id} show={true} name={name} onHide={onCloseModalHandler} />
                )}
            </Suspense>
            <NewDashboardLayout title={adminGreetings} description="Manage all your events here">
                <section className="event_overview_section">
                    {status === "loading" ? (
                        <div className="loading_view">
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            <div className="all_events">
                                <div className="all_events_row">
                                    <div className="d-flex align-items-center title_row">
                                        <h3>All events</h3>
                                        <FilterDropdown text="Sort" title={EventFilterGroup[filter]}>
                                            {filterArray.map((item, index) => {
                                                const itemKey = item.key as EventFilterType;
                                                return (
                                                    <Dropdown.Item key={index} onClick={() => onFilterEventHandler(itemKey)}>
                                                        {item.text}
                                                    </Dropdown.Item>
                                                );
                                            })}
                                        </FilterDropdown>
                                    </div>
                                    <div className="controls">
                                        <Link to="/dashboard/vendors">
                                            Vendors <span className="mx-1">{vendorData?.result?.length}</span>
                                        </Link>
                                        {(userDetails?.user_type === UserList.celebrant || userDetails?.user_type === UserList.planner) &&
                                            userDetails?.subscription_details?.id &&
                                            userDetails?.permission !== CollaboratorPermissionList.view_only && (
                                            <button className="create_event_btn" onClick={showCreateEventModalHandler}>
                                                <PlusIcon fill="#fff" /> <span>Create Event</span>
                                            </button>
                                        )}
                                    </div>
                                </div>
                                {userEvents && userEvents?.length > 0 ? (
                                    <div className="events_grid">
                                        {userEvents.map((eventItem) => {
                                            const eventImg = eventItem?.event_image ? eventItem?.event_image : eventImage(eventItem?.event_name);
                                            const cardHeaderStyle = eventCardStyle(eventItem?.event_name);
                                            const ceremonyText = eventItem?.ceremonies?.length <= 1 ? "Ceremony" : "Ceremonies";
                                            const todos =
                                                eventItem?.ceremonies?.length > 0
                                                    ? eventItem?.ceremonies.reduce((a, b) => {
                                                        if (b.checklist) {
                                                            const todoChecklist = b.checklist.reduce((count, item) => {
                                                                return count + (!item?.isDone ? 1 : 0);
                                                            }, 0);
                                                            return a + todoChecklist;
                                                        } else {
                                                            return a + 0;
                                                        }
                                                    }, 0)
                                                    : 0;
                                            const todosText = todos <= 1 ? "To-Do" : "To-Dos";
                                            return (
                                                <div key={eventItem.id} className="event_card_wrapper">
                                                    {userDetails?.subscription_details?.id && (
                                                        <SiteDropdown className="custom three_dot" title={<ThreedotIcon />}>
                                                            <Dropdown.Item onClick={showCreateEventModalHandler}>Add New Event</Dropdown.Item>
                                                            <Dropdown.Item
                                                                onClick={() => showDeleteEventModalHandler(eventItem.id, eventItem?.event_name)}>
                                                                Delete Event
                                                            </Dropdown.Item>
                                                            {/* <button>Archive Event</button> */}
                                                            {/* <button>Manage Event</button> */}
                                                        </SiteDropdown>
                                                    )}
                                                    <div key={eventItem.id} className="event_card" onClick={() => onClickEvent(eventItem.id)}>
                                                        <div className="card_header" style={cardHeaderStyle}></div>
                                                        <div className="card_content">
                                                            <div className="card_row_1">
                                                                <img src={eventImg} alt="event img" />
                                                            </div>
                                                            <h4 className="event_name">{eventItem?.event_name}</h4>
                                                            <div className="card_row_2">
                                                                <div className="ceremonies">
                                                                    <FoldedCalendarIcon />
                                                                    {eventItem?.ceremonies?.length} {ceremonyText}
                                                                </div>{" "}
                                                                <hr />
                                                                <div className="todos">
                                                                    <CircleDashedIcon />
                                                                    {todos} {todosText}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className="add_new_event_wrapper">
                                        <div className="add_new_event">
                                            <div className="icon_wrapper">
                                                <ShieldIcon />
                                            </div>
                                            <div className="text">
                                                <h4>You have no events yet.</h4>
                                                {userDetails?.subscription_details?.id && <p>Create an event to fill this space!</p>}
                                            </div>
                                            {userDetails?.subscription_details?.id && (
                                                <button onClick={showCreateEventModalHandler}>
                                                    <PlusIcon fill="#fff" /> Create an Event
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </section>
            </NewDashboardLayout>
        </>
    );
}
