import { useQuery } from "@tanstack/react-query";
import { Dropdown } from "react-bootstrap";
import { Suspense, useEffect, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon, FlagTriangleRightIcon, PencilIcon, PlusIcon, Trash2Icon } from "lucide-react";

import { useEvent } from "@/hooks/useEvent";
import { getEvent } from "@/axios/get-request";
import { EventdayFlowType } from "@/types";
import FilterDropdown from "@/components/FilterDropdown";
import AddPlanningTimelineModal from "@/components/modals/AddPlanningTimelineModal";
import EditEventdayTaskModal from "@/components/modals/EditEventdayTaskModal";
import DeleteEventTaskModal from "@/components/modals/DeleteEventTaskModal";
// import useUser from "@/hooks/useUser";
// import { CollaboratorPermissionList } from "@/data/types";
import ThreedotIcon from "@/assets/icon/ThreedotIcon";
import { generateColorFromId } from "@/helper";
import VendorPicker from "./modals/VendorPicker";
import "@/styles/checklist_new.scss";
import "@/styles/event_day_flow.scss";
import "@/styles/planning_timeline.scss";
import CircleDashedIcon from "@/assets/icon/CircleDashedIcon";

enum ModalList {
    add_planning_timeline_modal = "add_planning_timeline_modal",
    edit_planning_timeline_modal = "edit_planning_timeline_modal",
    delete_planning_timeline_modal = "delete_planning_timeline_modal",
}

const timelineHeader = ["Item", "Stage", "Deadline", "Tag", "Owner", "Notes"];

const Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

type EventDayTask = EventdayFlowType[0];

export default function PlanningTimeline() {
    const [modal, setModal] = useState<string | null>(null);
    const { selectedEventId, activeCeremonyId, setActiveCeremonyId } = useEvent();
    const [counter, setCounter] = useState({
        start: 0,
        end: 6,
    });
    const [selectedEventDayTask] = useState<EventDayTask | null>(null);
    // const { data: userData } = useUser();
    const { data, status } = useQuery({
        queryKey: [`ceremony_${activeCeremonyId}_${selectedEventId}`],
        queryFn: () => getEvent(selectedEventId),
    });

    function switchMonth() {
        setCounter((prev) => {
            if (prev.start === 0) {
                return {
                    ...prev,
                    start: prev.start + 6,
                    end: prev.end + 6,
                };
            } else if (prev.start === 6) {
                return {
                    ...prev,
                    start: prev.start - 6,
                    end: prev.end - 6,
                };
            }

            return prev;
        });
    }

    const eventData = data?.data;
    // const userDetails = userData?.result ? userData?.result : null;
    // const viewOnly = userDetails?.permission === CollaboratorPermissionList.view_only;

    useEffect(() => {
        if (
            status === "success" &&
            activeCeremonyId === "all" &&
            eventData?.result?.ceremonies &&
            Array.isArray(eventData?.result?.ceremonies) &&
            eventData?.result?.ceremonies.length > 0
        ) {
            setActiveCeremonyId(eventData?.result?.ceremonies[0]?.id);
        }
    }, [status, data, selectedEventId]);

    function onCloseModal() {
        setModal(null);
    }

    function showPlanningTimelineModal() {
        setModal(ModalList.add_planning_timeline_modal);
    }

    const ceremonies = status === "success" && eventData?.result?.ceremonies ? eventData?.result.ceremonies : [];
    const selectedCeremony = activeCeremonyId ? ceremonies.filter((item) => item.id === activeCeremonyId) : [];
    const ceremonyName = selectedCeremony ? selectedCeremony[0]?.name : "";
    const eventName = eventData?.result ? eventData?.result?.event_name : "";
    const ceremonyChecklists: EventdayFlowType = [];

    if (selectedCeremony[0]?.eventdayFlow) {
        selectedCeremony[0]?.eventdayFlow.map((item) => {
            if (item.ceremony.id === activeCeremonyId) {
                ceremonyChecklists.push(item);
            }
        });
    }

    // const activeCeremony = eventData?.result?.ceremonies ? eventData?.result?.ceremonies.filter((item) => item.id === activeCeremonyId) : [];
    // const eventdayFlow = activeCeremony[0]?.eventdayFlow && Array.isArray(activeCeremony[0]?.eventdayFlow)
    // ? activeCeremony[0]?.eventdayFlow : null;
    // const markedAsDoneEvents = eventdayFlow ? eventdayFlow.filter((item) => item.isDone) : null;
    // const noOfDoneEvents = markedAsDoneEvents ? markedAsDoneEvents.length : 0;
    // const __percentage = noOfDoneEvents && eventdayFlow ? (noOfDoneEvents / eventdayFlow.length) * 100 : 0;
    // const percentage = __percentage.toFixed(0);

    const vendorDetails = {
        id: "",
        name: "",
    };

    return (
        <>
            <Suspense>
                {modal === ModalList.add_planning_timeline_modal && (
                    <AddPlanningTimelineModal eventName={eventName} ceremonyName={ceremonyName} onClose={onCloseModal} />
                )}
                {modal === ModalList.edit_planning_timeline_modal && selectedEventDayTask && (
                    <EditEventdayTaskModal eventName={eventName} eventDayTask={selectedEventDayTask} onClose={onCloseModal} />
                )}
                {modal === ModalList.delete_planning_timeline_modal && selectedEventDayTask && (
                    <DeleteEventTaskModal
                        eventDayTaskId={selectedEventDayTask.id}
                        eventDayTaskName={selectedEventDayTask.task}
                        onClose={onCloseModal}
                    />
                )}
            </Suspense>
            <section className="checklist_page planning_timeline event_day_flow">
                <div className="checklist_view mt-4">
                    <div className="title_row">
                        <div>
                            <FlagTriangleRightIcon />
                            <h3>Planning Timeline</h3>
                        </div>
                        <div className="button_row">
                            <FilterDropdown title={ceremonyName} disabled={!selectedEventId}>
                                {ceremonies.map((ceremony) => (
                                    <Dropdown.Item key={ceremony.id} onClick={() => setActiveCeremonyId(ceremony.id)}>
                                        {ceremony.name}
                                    </Dropdown.Item>
                                ))}
                            </FilterDropdown>
                        </div>
                    </div>
                    <div className="event_day_view">
                        <div className="months_group">
                            <button onClick={switchMonth}>
                                <ChevronLeftIcon />
                            </button>
                            <div className="monthly_task_view">
                                {Months.slice(counter.start, counter.end).map((month, index) => {
                                    const color = generateColorFromId(month);
                                    const style = { backgroundColor: color };
                                    return (
                                        <div className="month_view" key={index}>
                                            <div className="handlebar" style={style} />
                                            <h4>{month}</h4>
                                            <h6>
                                                00 <sup>Tasks</sup>
                                            </h6>
                                        </div>
                                    );
                                })}
                            </div>
                            <button onClick={switchMonth}>
                                <ChevronRightIcon />
                            </button>
                        </div>
                        <div className="this_month_list table_view">
                            <div className="table_row">
                                <h3>This month</h3>
                                <div className="action_group">
                                    <button onClick={showPlanningTimelineModal}>
                                        Add new <PlusIcon />
                                    </button>
                                    <button>
                                        <ThreedotIcon />
                                    </button>
                                </div>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <td>
                                            <input type="checkbox" />
                                        </td>
                                        {timelineHeader.map((item, index) => (
                                            <td key={index}>{item}</td>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input type="checkbox" />
                                        </td>
                                        <td>
                                            <input />
                                        </td>
                                        <td>
                                            <div className="stage_element">
                                                <CircleDashedIcon />
                                                <input placeholder="Stage" />
                                            </div>
                                        </td>
                                        <td>
                                            <input placeholder="00 00 0000" />
                                        </td>
                                        <td>
                                            <input placeholder="Tag" />
                                        </td>
                                        <td>
                                            <VendorPicker addNew={false} vendor={vendorDetails} />
                                        </td>
                                        <td>
                                            <input placeholder="--" />
                                        </td>
                                        <td>
                                            <div className="button_group">
                                                <button>
                                                    <PencilIcon />
                                                </button>
                                                <button>
                                                    <Trash2Icon />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="next_month_list table_view">
                            <div className="table_row">
                                <h3>Next month</h3>
                                <div className="action_group">
                                    <button onClick={showPlanningTimelineModal}>
                                        Add new <PlusIcon />
                                    </button>
                                    <button>
                                        <ThreedotIcon />
                                    </button>
                                </div>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <td>
                                            <input type="checkbox" />
                                        </td>
                                        {timelineHeader.map((item, index) => (
                                            <td key={index}>{item}</td>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input type="checkbox" />
                                        </td>
                                        <td>
                                            <input />
                                        </td>
                                        <td>
                                            <div className="stage_element">
                                                <CircleDashedIcon />
                                                <input placeholder="Stage" />
                                            </div>
                                        </td>
                                        <td>
                                            <input placeholder="00 00 0000" />
                                        </td>
                                        <td>
                                            <input placeholder="Tag" />
                                        </td>
                                        <td>
                                            <VendorPicker addNew={false} vendor={vendorDetails} />
                                        </td>
                                        <td>
                                            <input placeholder="--" />
                                        </td>
                                        <td>
                                            <div className="button_group">
                                                <button>
                                                    <PencilIcon />
                                                </button>
                                                <button>
                                                    <Trash2Icon />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
