/* eslint-disable */
import { Geometry2d, RecordProps, Rectangle2d, ShapeUtil, SVGContainer, T, TLBaseShape, TLResizeInfo, resizeBox } from "tldraw";

type ICustomShape = TLBaseShape<
	"gift_element",
	{
		w: number;
		h: number;
		color: string;
	}
>;

// [2]
export class GiftElement extends ShapeUtil<ICustomShape> {
	static override type = "gift_element" as const;
	static override props: RecordProps<ICustomShape> = {
		w: T.number,
		h: T.number,
		color: T.string,
	};

	// [b]
	getDefaultProps(): ICustomShape["props"] {
		return {
			w: 34,
			h: 16,
			color: "#D9D9D9",
		};
	}

	// [c]
	override canEdit() {
		return true;
	}
	override canResize() {
		return true;
	}
	override isAspectRatioLocked() {
		return false;
	}

	// [d]
	getGeometry(shape: ICustomShape): Geometry2d {
		return new Rectangle2d({
			width: shape.props.w,
			height: shape.props.h,
			isFilled: true,
		});
	}

	// [e]
	override onResize(shape: ICustomShape, info: TLResizeInfo<any>) {
		return resizeBox(shape, info);
	}

	// [f]
	component(shape: ICustomShape) {
		return (
			<SVGContainer>
				<svg
					fill="none"
					viewBox="10.864 5.099137931034482 34.44 16.25"
					xmlns="http://www.w3.org/2000/svg"
					// style="max-height: 500px"
					width={shape.props.w}
					height={shape.props.h}>
					<rect rx="2" height="26" width="56" />
					<path
						strokeWidth="0.2"
						stroke="#404040"
						fill="#D9D9D9"
						d="M12.1 19L12.1 7C12.1 6.50294 12.5029 6.1 13 6.1L43 6.1C43.4971 6.1 43.9 6.50294 43.9 7V19C43.9 19.4971 43.4971 19.9 43 19.9L13 19.9C12.5029 19.9 12.1 19.4971 12.1 19Z"
					/>
					<g clip-path="url(#clip0_11634_7397)">
						<path
							stroke-linejoin="round"
							stroke-linecap="round"
							strokeWidth="0.714286"
							stroke="#404040"
							d="M27.9973 10.6668V15.0001M27.9973 10.6668C27.8767 10.1699 27.6691 9.74517 27.4016 9.44787C27.134 9.15057 26.8189 8.99454 26.4973 9.00015C26.2763 9.00015 26.0643 9.08794 25.9081 9.24422C25.7518 9.4005 25.664 9.61247 25.664 9.83348C25.664 10.0545 25.7518 10.2665 25.9081 10.4227C26.0643 10.579 26.2763 10.6668 26.4973 10.6668M27.9973 10.6668C28.1179 10.1699 28.3255 9.74517 28.5931 9.44787C28.8606 9.15057 29.1758 8.99454 29.4973 9.00015C29.7183 9.00015 29.9303 9.08794 30.0866 9.24422C30.2429 9.4005 30.3306 9.61247 30.3306 9.83348C30.3306 10.0545 30.2429 10.2665 30.0866 10.4227C29.9303 10.579 29.7183 10.6668 29.4973 10.6668M30.3306 12.0001V14.3335C30.3306 14.5103 30.2604 14.6799 30.1354 14.8049C30.0104 14.9299 29.8408 15.0001 29.664 15.0001H26.3306C26.1538 15.0001 25.9843 14.9299 25.8592 14.8049C25.7342 14.6799 25.664 14.5103 25.664 14.3335V12.0001M25.3306 10.6668H30.664C30.8481 10.6668 30.9973 10.8161 30.9973 11.0001V11.6668C30.9973 11.8509 30.8481 12.0001 30.664 12.0001H25.3306C25.1466 12.0001 24.9973 11.8509 24.9973 11.6668V11.0001C24.9973 10.8161 25.1466 10.6668 25.3306 10.6668Z"
						/>
					</g>
					<g clip-path="url(#clip1_11634_7397)">
						<path
							stroke-linejoin="round"
							stroke-linecap="round"
							strokeWidth="0.714286"
							stroke="#404040"
							d="M34.9973 12.6668V17.0001M34.9973 12.6668C34.8767 12.1699 34.6691 11.7452 34.4016 11.4479C34.134 11.1506 33.8189 10.9945 33.4973 11.0001C33.2763 11.0001 33.0643 11.0879 32.9081 11.2442C32.7518 11.4005 32.664 11.6125 32.664 11.8335C32.664 12.0545 32.7518 12.2665 32.9081 12.4227C33.0643 12.579 33.2763 12.6668 33.4973 12.6668M34.9973 12.6668C35.1179 12.1699 35.3255 11.7452 35.5931 11.4479C35.8606 11.1506 36.1758 10.9945 36.4973 11.0001C36.7183 11.0001 36.9303 11.0879 37.0866 11.2442C37.2429 11.4005 37.3306 11.6125 37.3306 11.8335C37.3306 12.0545 37.2429 12.2665 37.0866 12.4227C36.9303 12.579 36.7183 12.6668 36.4973 12.6668M37.3306 14.0001V16.3335C37.3306 16.5103 37.2604 16.6799 37.1354 16.8049C37.0104 16.9299 36.8408 17.0001 36.664 17.0001H33.3306C33.1538 17.0001 32.9843 16.9299 32.8592 16.8049C32.7342 16.6799 32.664 16.5103 32.664 16.3335V14.0001M32.3306 12.6668H37.664C37.8481 12.6668 37.9973 12.8161 37.9973 13.0001V13.6668C37.9973 13.8509 37.8481 14.0001 37.664 14.0001H32.3306C32.1466 14.0001 31.9973 13.8509 31.9973 13.6668V13.0001C31.9973 12.8161 32.1466 12.6668 32.3306 12.6668Z"
						/>
					</g>
					<g clip-path="url(#clip2_11634_7397)">
						<path
							stroke-linejoin="round"
							stroke-linecap="round"
							strokeWidth="0.714286"
							stroke="#404040"
							d="M20.9973 12.6668V17.0001M20.9973 12.6668C20.8767 12.1699 20.6691 11.7452 20.4016 11.4479C20.134 11.1506 19.8189 10.9945 19.4973 11.0001C19.2763 11.0001 19.0643 11.0879 18.9081 11.2442C18.7518 11.4005 18.664 11.6125 18.664 11.8335C18.664 12.0545 18.7518 12.2665 18.9081 12.4227C19.0643 12.579 19.2763 12.6668 19.4973 12.6668M20.9973 12.6668C21.1179 12.1699 21.3255 11.7452 21.5931 11.4479C21.8606 11.1506 22.1758 10.9945 22.4973 11.0001C22.7183 11.0001 22.9303 11.0879 23.0866 11.2442C23.2429 11.4005 23.3306 11.6125 23.3306 11.8335C23.3306 12.0545 23.2429 12.2665 23.0866 12.4227C22.9303 12.579 22.7183 12.6668 22.4973 12.6668M23.3306 14.0001V16.3335C23.3306 16.5103 23.2604 16.6799 23.1354 16.8049C23.0104 16.9299 22.8408 17.0001 22.664 17.0001H19.3306C19.1538 17.0001 18.9843 16.9299 18.8592 16.8049C18.7342 16.6799 18.664 16.5103 18.664 16.3335V14.0001M18.3306 12.6668H23.664C23.8481 12.6668 23.9973 12.8161 23.9973 13.0001V13.6668C23.9973 13.8509 23.8481 14.0001 23.664 14.0001H18.3306C18.1466 14.0001 17.9973 13.8509 17.9973 13.6668V13.0001C17.9973 12.8161 18.1466 12.6668 18.3306 12.6668Z"
						/>
					</g>
					<defs>
						<clipPath id="clip0_11634_7397">
							<rect transform="translate(24 8)" fill="white" height="8" width="8" />
						</clipPath>
						<clipPath id="clip1_11634_7397">
							<rect transform="translate(31 10)" fill="white" height="8" width="8" />
						</clipPath>
						<clipPath id="clip2_11634_7397">
							<rect transform="translate(17 10)" fill="white" height="8" width="8" />
						</clipPath>
					</defs>
				</svg>
			</SVGContainer>
		);
	}

	// [g]
	indicator() {
		return;
	}
}
