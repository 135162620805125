/* eslint-disable */
import { Geometry2d, RecordProps, Rectangle2d, ShapeUtil, SVGContainer, T, TLBaseShape, TLResizeInfo, resizeBox } from "tldraw";

type ICustomShape = TLBaseShape<
	"single_closed_door",
	{
		w: number;
		h: number;
		color: string;
	}
>;

// [2]
export class SingleClosedDoorElement extends ShapeUtil<ICustomShape> {
	static override type = "single_closed_door" as const;
	static override props: RecordProps<ICustomShape> = {
		w: T.number,
		h: T.number,
		color: T.string,
	};

	// [b]
	getDefaultProps(): ICustomShape["props"] {
		return {
			w: 18,
			h: 5,
			color: "#D9D9D9",
		};
	}

	// [c]
	override canEdit() {
		return true;
	}
	override canResize() {
		return true;
	}
	override isAspectRatioLocked() {
		return false;
	}

	// [d]
	getGeometry(shape: ICustomShape): Geometry2d {
		return new Rectangle2d({
			width: shape.props.w,
			height: shape.props.h,
			isFilled: true,
		});
	}

	// [e]
	override onResize(shape: ICustomShape, info: TLResizeInfo<any>) {
		return resizeBox(shape, info);
	}

	// [f]
	component(shape: ICustomShape) {
		return (
			<SVGContainer>
				<svg
					fill="none"
					viewBox="18.704 11.038793103448276 18.312 4.875"
					xmlns="http://www.w3.org/2000/svg"
					// styl÷e="max-height: 500px"
					width={shape.props.w}
					height={shape.props.h}>
					<rect rx="2" height="26" width="56" />
					<rect strokeWidth="0.2" stroke="#404040" rx="0.1" height="0.2" width="11.8" y="13.1" x="22.1" />
					<path strokeWidth="0.4" stroke="#404040" d="M20 12H21.6C21.8209 12 22 12.1791 22 12.4V14.6C22 14.8209 21.8209 15 21.6 15H20" />
					<path strokeWidth="0.4" stroke="#404040" d="M36 12H34.4C34.1791 12 34 12.1791 34 12.4V14.6C34 14.8209 34.1791 15 34.4 15H36" />
				</svg>
			</SVGContainer>
		);
	}

	// [g]
	indicator() {
		return;
	}
}
