import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useState } from "react";
import { format } from "date-fns";
import { PencilIcon, AlarmClockIcon, ClockIcon, CreditCardIcon, LocateIcon, Trash2Icon } from "lucide-react";

import { EventdayFlowType } from "@/types";
import Spinner from "@/components/Spinner";

import EventDayflowActiveIcon from "@/components/EventDayflowActiveIcon";
import EventDayflowInactiveIcon from "@/components/EventDayflowInactiveIcon";
import { updateEventDayFlowDetails } from "@/axios/put-request";
import VendorPicker from "./modals/VendorPicker";
import "@/styles/checklist_new.scss";
import "@/styles/event_day_flow.scss";

type EventDayTask = EventdayFlowType[0];

interface Props {
    item: EventDayTask;
    setModal: React.Dispatch<React.SetStateAction<string | null>>;
    index: number;
    activeCeremonyId: string;
    selectedEventId: string;
    viewOnly: boolean;
    editEventDayTaskHandler: (item: EventDayTask) => void;
    deleteEventDayTaskHandler: (item: EventDayTask) => void;
}

export default function EventDayflowItem({
    item,
    editEventDayTaskHandler,
    deleteEventDayTaskHandler,
    index,
    activeCeremonyId,
    selectedEventId,
    viewOnly,
}: Props) {
    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();

    const counter = index + 1;
    const status = item.isDone ? "Done" : "Mark Done";
    const className = item.isDone ? "active" : "";
    const vendorDetails = {
        id: item?.vendor?.id ? item?.vendor?.id : "",
        name: item?.vendor?.name ? item?.vendor?.name : "",
    };

    async function toggleEventDayFlowStatus(eventDayFlowItem: EventDayTask, isDone: boolean) {
        try {
            setLoading(true);
            const updateEventDayFlowRequest = await updateEventDayFlowDetails(eventDayFlowItem.id, selectedEventId, activeCeremonyId, {
                isDone: !isDone,
            });
            if (!updateEventDayFlowRequest.data.status) {
                setLoading(false);
                return toast.error(updateEventDayFlowRequest.data.message);
            }
            setLoading(false);
            queryClient.invalidateQueries({ queryKey: [`ceremony_${activeCeremonyId}_${selectedEventId}`] });
        } catch (error) {
            setLoading(false);
            return toast.error("Oops an error occured");
        }
    }

    return (
        <div className="event_day_flow_item">
            <div className="status">{item.isDone ? <EventDayflowActiveIcon /> : <EventDayflowInactiveIcon counter={counter} />}</div>
            <div className="event_day_item_group">
                <div className="event_day_flow_body">
                    <div className="item__row_1">
                        <h4>{item.task}</h4>
                        {!viewOnly && (
                            <div className="event_day_item_action_group">
                                <div>
                                    <button onClick={() => editEventDayTaskHandler(item)}>
                                        <PencilIcon />
                                    </button>
                                    <button className="delete_button" onClick={() => deleteEventDayTaskHandler(item)}>
                                        <Trash2Icon />
                                    </button>
                                </div>
                                <button
                                    className={`status ${className}`}
                                    onClick={() => toggleEventDayFlowStatus(item, item.isDone)}
                                    disabled={loading}>
                                    {loading ? <Spinner size={20} /> : status}
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="item__row_2">
                        <div className="item_group group__1">
                            <time>
                                <ClockIcon /> {item.duration ? item.duration : "--"}
                            </time>{" "}
                            <hr />
                            <time>
                                <AlarmClockIcon />
                                {item.start_time ? item.start_time : "--"}
                            </time>
                            <hr className="list_separator" />
                        </div>
                        <div className="item_group group__2">
                            <time>
                                <CreditCardIcon /> {item.date ? format(new Date(item.date), "EEE do MMM") : "--"}
                            </time>
                            <hr />
                            <div className="bride_group">
                                <div>
                                    <LocateIcon /> {item?.venue ? item?.venue : "--"}
                                </div>
                            </div>{" "}
                            <hr className="list_separator" />
                        </div>
                        <div className="item_group">
                            <VendorPicker vendor={vendorDetails} />
                        </div>
                    </div>
                </div>
                {!viewOnly && (
                    <button className={`status ${className}`} onClick={() => toggleEventDayFlowStatus(item, item.isDone)} disabled={loading}>
                        {loading ? <Spinner size={20} /> : status}
                    </button>
                )}
            </div>
        </div>
    );
}
