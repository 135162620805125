/* eslint-disable */
import { Geometry2d, RecordProps, Rectangle2d, ShapeUtil, SVGContainer, T, TLBaseShape, TLResizeInfo, resizeBox } from "tldraw";

type ICustomShape = TLBaseShape<
	"double_closed_door",
	{
		w: number;
		h: number;
		color: string;
	}
>;

// [2]
export class DoubleClosedDoorElement extends ShapeUtil<ICustomShape> {
	static override type = "double_closed_door" as const;
	static override props: RecordProps<ICustomShape> = {
		w: T.number,
		h: T.number,
		color: T.string,
	};

	// [b]
	getDefaultProps(): ICustomShape["props"] {
		return {
			w: 36,
			h: 4,
			color: "#D9D9D9",
		};
	}

	// [c]
	override canEdit() {
		return true;
	}
	override canResize() {
		return true;
	}
	override isAspectRatioLocked() {
		return false;
	}

	// [d]
	getGeometry(shape: ICustomShape): Geometry2d {
		return new Rectangle2d({
			width: shape.props.w,
			height: shape.props.h,
			isFilled: true,
		});
	}

	// [e]
	override onResize(shape: ICustomShape, info: TLResizeInfo<any>) {
		return resizeBox(shape, info);
	}

	// [f]
	component(shape: ICustomShape) {
		return (
			<SVGContainer>
				<svg
					fill="none"
					viewBox="10.192 10.982758620689655 36.008 4.93103448275862"
					xmlns="http://www.w3.org/2000/svg"
					// style="max-height: 500px"
					width={shape.props.w}
					height={shape.props.h}>
					<rect rx="2" height="26" width="56" />
					<rect strokeWidth="0.2" stroke="#404040" rx="0.1" height="0.2" width="11.8" y="13.1" x="29.1" />
					<path strokeWidth="0.4" stroke="#404040" d="M45 12H41.4C41.1791 12 41 12.1791 41 12.4V14.6C41 14.8209 41.1791 15 41.4 15H45" />
					<rect strokeWidth="0.2" stroke="#404040" rx="0.1" height="0.2" width="11.8" y="13.1" x="15.1" />
					<path strokeWidth="0.4" stroke="#404040" d="M11 12H14.6C14.8209 12 15 12.1791 15 12.4V14.6C15 14.8209 14.8209 15 14.6 15H11" />
				</svg>
			</SVGContainer>
		);
	}

	// [g]
	indicator() {
		return;
	}
}
