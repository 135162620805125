import { v4 as uuidv4 } from "uuid";
import { ChangeEvent, CSSProperties, memo, useEffect, useRef, useState } from "react";
import { Editor, TLShapeId, Tldraw, exportToBlob } from "tldraw";
import FuzzySearch from "fuzzy-search";
import { Dropdown } from "react-bootstrap";
import { jsPDF } from "jspdf";
import { ExpandIcon, PlusIcon, ShrinkIcon } from "lucide-react";
import "tldraw/tldraw.css";

import logo from "@/assets/img/logo_full.png";
import { fillSpaceElements, fillSpaceObjectElements, floorPlanTableAndChairsElements } from "@/utils/draw_floor_plan_icons";
import { FloorPlanObjectList, FloorPlanObjectListType } from "@/data/types";
import useFloorPlan from "@/hooks/useFloorPlan";
import Spinner from "@/components/Spinner";
import Search from "@/assets/icon/Search";
import SquareDashedIcon from "@/assets/icon/SquareDashedIcon";
import { createFloorPlanRequest } from "@/axios/post-request";
import { useEvent } from "@/hooks/useEvent";
import SiteDropdown from "@/components/Dropdown";
import ThreeDotsIcon from "@/assets/icon/ThreeDotsIcon";
import useToast from "@/hooks/useToast";
import { deleteFloorplanRequest } from "@/axios/delete-request";
import { SofaElement } from "./FloorplanElements/SofaElement";
import { SingleSofaElement } from "./FloorplanElements/SingleSofaElement";
import { DoubleSofaElement } from "./FloorplanElements/DoubleSofaElement";
import { RoundTable10Element } from "./FloorplanElements/RoundTable10Element";
import { RoundTable8Element } from "./FloorplanElements/RoundTable8Element";
import { RectangleTable6Element } from "./FloorplanElements/RectangleTable6Element";
import { RectangleTable8Element } from "./FloorplanElements/RectangleTable8Element";
import { RectangleTable10Element } from "./FloorplanElements/RectangleTable10Element";
import { RectangleTable3Element } from "./FloorplanElements/RectangleTable3Element";
import { GiftElement } from "./FloorplanElements/GiftElement";
import { SingleClosedDoorElement } from "./FloorplanElements/SingleClosedDoorElement";
import { SingleOpenDoorElement } from "./FloorplanElements/SingleOpenDoorElement";
import { DoubleOpenDoorElement } from "./FloorplanElements/DoubleOpenDoorElement";
import { DoubleClosedDoorElement } from "./FloorplanElements/DoubleClosedDoorElement";
import { StageStairsElement } from "./FloorplanElements/StageStairsElement";
import { StageElement } from "./FloorplanElements/StageElement";
import { CateringStationElement } from "./FloorplanElements/CateringStationElement";
import { DessertElement } from "./FloorplanElements/DessertElement";
import { DrinksElement } from "./FloorplanElements/DrinksElement";
import { CocktailElement } from "./FloorplanElements/CocktailElement";
import { CakeElement } from "./FloorplanElements/CakeElement";
import { BuffetElement } from "./FloorplanElements/BuffetElement";
import { BandElement } from "./FloorplanElements/BandElement";
import { DJElement } from "./FloorplanElements/DJElement";
import { SoundElement } from "./FloorplanElements/SoundElement";
import { DanceFloorElement } from "./FloorplanElements/DanceFloorElement";
import useClickOutside from "@/hooks/useClickOutside";
import { FloorplanLogo } from "./FloorplanElements/FloorplanLogo";

interface FloorPlanElementProps {
	elementGroup: "chairs" | "square_tables" | "round_tables" | "rectangle_tables";
	addFloorPlanElementHandler: (element_id: string) => void;
}

interface Props {
	fullViewHandler: (value: boolean) => void;
}

const style = {
    width: "100%",
    height: "500px",
    display: "flex",
    alignItems: "flex-start",
};

const canvasStyle = {
    width: "100%",
    position: "absolute",
    inset: 0,
} as CSSProperties;

type FloorplanGroupType = Array<{ type: FloorPlanObjectListType; text: string; icon: JSX.Element; element_id?: string; id?: string }>;

function FloorPlanElement({ elementGroup, addFloorPlanElementHandler }: FloorPlanElementProps) {
    return (
        <div className="floor_plan_element">
            <h6>{floorPlanTableAndChairsElements[elementGroup].title}</h6>
            <div className="table_chair_group mt-2">
                {floorPlanTableAndChairsElements[elementGroup].elements.map((item: FloorplanGroupType[0], index) => (
                    <button key={index} onClick={() => addFloorPlanElementHandler(item.element_id as string)}>
                        {item.icon}
                    </button>
                ))}
            </div>
        </div>
    );
}

const searchGroup = [
    ...fillSpaceObjectElements.table_chairs__category,
    ...fillSpaceObjectElements.entertainment__category,
    ...fillSpaceObjectElements.food_drinks__category,
    ...fillSpaceObjectElements.stage__category,
    ...fillSpaceObjectElements.doors__category,
    ...fillSpaceObjectElements.gifts__category,
    ...fillSpaceObjectElements.shapes__category,
];

function FloorplanTLDrawCanvas({ fullViewHandler }: Props) {
    const [pickElementStage, setPickElementStage] = useState(fillSpaceElements[0].type);
    const { selectedEventId, activeCeremonyId } = useEvent();
    const { loadingToast, updateToast } = useToast();
    const [editor, setEditor] = useState<Editor>();
    const toastId = useRef("loading_floorplan_id");
    const {
        floorPlanStage,
        activeFloorPlanStageId,
        sidebar,
        updateFloorPlanName,
        updateActiveFloorPlanStageId,
        deleteFloorPlanHandler,
        addDefaultFloorPlanHandler,
    } = useFloorPlan();
    const [searchText, setSearchText] = useState("");
    const [searchResult, setSearchResult] = useState<FloorplanGroupType>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const ref = useRef(null);
    useClickOutside(ref, unFocusFloorplan);

    // const currentFloorPlan = floorPlanStage.filter((item) => item.id === activeFloorPlanStageId);
    function unFocusFloorplan() {
        setActiveIndex(null);
    }
    function updateEditorOnMount(editorInstance: Editor) {
        if (!editor) {
            setEditor(editorInstance);
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function renameFloorPlanHandler(event: any) {
        if (activeFloorPlanStageIndex !== null) {
            updateFloorPlanName(event?.target?.value, activeFloorPlanStageIndex);
        }
    }

    const pickElementStageValue = pickElementStage as
		| "entertainment__category"
		| "food_drinks__category"
		| "stage__category"
		| "doors__category"
		| "gifts__category"
		| "shapes__category";

    const floorPlanGroupElements = fillSpaceObjectElements[pickElementStageValue] as Array<{
		title?: string;
		type: FloorPlanObjectListType;
		icon: JSX.Element;
		url?: string;
		id: string;
		element_id: string;
	}>;

    useEffect(() => {
        if (searchText) {
            setIsSearching(true);
            const searcher = new FuzzySearch(searchGroup, ["text"], {
                caseSensitive: false,
            });
            const result = searcher.search(searchText);
            setSearchResult(result);
            if (result) {
                setIsSearching(false);
            }
        }
    }, [searchText.length]);

    // const currentFloorPlan = floorPlanStage.filter((item) => item.id === activeFloorPlanStageId);

    const activeFloorPlanStageIndex = activeFloorPlanStageId ? floorPlanStage.findIndex((item) => item.id === activeFloorPlanStageId) : null;

    function searchTextHandler(event: ChangeEvent<HTMLInputElement>) {
        setSearchText(event.target.value);
    }

    function addFloorPlanHandler(name: string, id: string) {
        const stageWidth = window.innerWidth;
        const stageHeight = window.innerHeight;
        const dimensionElementSizes = {
            height: 400,
            width: 1000,
        };
        const divider = sidebar ? 10 : 5;
        const rectX = (stageWidth - dimensionElementSizes.width) / divider;
        const rectY = (stageHeight - dimensionElementSizes.height) / 10;
        const floorPlanObjectId = `${FloorPlanObjectList.regular__dimensions}__${id}`;

        return {
            id,
            floor_plan_name: name ? name : "New Floor Plan",
            floor_plan_icon: "",
            activeFloorPlanElementId: null,
            stage: [
                {
                    ...dimensionElementSizes,
                    fill: "white",
                    id: floorPlanObjectId,
                    x: rectX,
                    y: rectY,
                    type: FloorPlanObjectList.regular__dimensions,
                },
            ],
        };
    }

    const floorPlanStageCount = floorPlanStage.length > 0 ? floorPlanStage.length + 1 : "";
    const newFloorplanName = `New Floor plan ${floorPlanStageCount}`;

    async function addNewFloorPlanHandler() {
        try {
            const floorPlanId = uuidv4();
            addDefaultFloorPlanHandler(newFloorplanName, floorPlanId);
            const floorPlanDetails = addFloorPlanHandler(newFloorplanName, floorPlanId);
            await createFloorPlanRequest(selectedEventId, {
                ...floorPlanDetails,
                event_id: selectedEventId,
                ceremony_id: activeCeremonyId,
            });
        } catch (error) {
            console.log("error", error);
        }
    }

    async function onDeleteFloorPlanHandler() {
        try {
            if (activeFloorPlanStageId) {
                loadingToast(toastId);
                await deleteFloorplanRequest(activeFloorPlanStageId, selectedEventId);
                deleteFloorPlanHandler(activeFloorPlanStageId);
                indexedDB.deleteDatabase(`TLDRAW_DOCUMENT_v2floor_plan_${activeFloorPlanStageId}`);
                const nextFloorPlan = floorPlanStage[floorPlanStage.length - 1];
                if (nextFloorPlan) updateActiveFloorPlanStageId(nextFloorPlan.id);
                updateToast(toastId, "success", "Floor plan deleted");
            }
        } catch (error) {
            console.log("error", error);
            updateToast(toastId, "success", "Error deleting floor plan");
        }
    }

    function addFloorplanElement(elementId: string) {
        if (!editor) return;
        if (elementId) {
            const randomNumber = Math.random() * 200;
            const zoomLevel = editor.getZoomLevel();
            const height = 100;
            const width = 100;
            const scaledHeight = height / zoomLevel;
            const scaledWidth = width / zoomLevel;

            editor.createShape({
                type: elementId,
                x: editor.getViewportScreenCenter().x + randomNumber,
                y: editor.getViewportScreenCenter().y + randomNumber,
                props: {
                    h: scaledHeight,
                    w: scaledWidth,
                },
            });
        }
    }

    const currentFloorPlanDetail = activeFloorPlanStageId ? floorPlanStage.filter((item) => item.id === activeFloorPlanStageId)[0] : null;

    // Helper function: Convert Blob to Data URL
    function blobToDataURL(blob: Blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    async function exportFloorPlanAsPDF() {
        try {
            if (!editor) return;
            const shapeIds = editor.getCurrentPageShapeIds() as unknown as TLShapeId[];
            const uriBlob = await exportToBlob({ editor, format: "png", ids: [...shapeIds], opts: { background: false } });
            const pdfInstance = new jsPDF({
                orientation: "portrait",
                unit: "px",
                format: "a4",
                compress: true,
            });

            const pdf = pdfInstance;
            const img = new Image();
            const dataUrl = (await blobToDataURL(uriBlob)) as string;

            img.src = dataUrl;
            await img.decode(); // Wait for the image to fully load

            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            pdf.setFillColor(255, 255, 255); // RGB for white
            pdf.rect(0, 0, pageWidth, pageHeight, "F");

            // Scale image to fit within the page if necessary
            let imgWidth = img.width;
            let imgHeight = img.height;
            const aspectRatio = img.width / img.height;

            if (imgWidth > pageWidth) {
                imgWidth = pageWidth;
                imgHeight = pageWidth / aspectRatio;
            }
            if (imgHeight > pageHeight) {
                imgHeight = pageHeight;
                imgWidth = pageHeight * aspectRatio;
            }
            pdf.addImage(dataUrl, "PNG", 0, 0, imgWidth, imgHeight);
            const logoImg = new Image();
            logoImg.src = logo;
            logoImg.height = 20;
            logoImg.width = 100;
            pdf.addImage(logoImg, "PNG", 20, 10, 100, 20);
            pdf.save(`${currentFloorPlanDetail?.floor_plan_name}-floor-plan.pdf`);
        } catch (error) {
            console.log("error", error);
        }
    }

    const customShapes = [
        FloorplanLogo,
        SofaElement,
        SingleSofaElement,
        DoubleSofaElement,
        RoundTable10Element,
        RoundTable8Element,
        RectangleTable3Element,
        RectangleTable6Element,
        RectangleTable8Element,
        RectangleTable10Element,
        GiftElement,
        SingleClosedDoorElement,
        SingleOpenDoorElement,
        DoubleOpenDoorElement,
        DoubleClosedDoorElement,
        StageStairsElement,
        StageElement,
        CateringStationElement,
        DessertElement,
        DrinksElement,
        CocktailElement,
        CakeElement,
        BuffetElement,
        BandElement,
        DJElement,
        SoundElement,
        DanceFloorElement,
    ];

    return (
        <section className="floor_plan">
            <div className="floor_plan_group">
                <div className="floor_plan_left_group">
                    <div className="added_floor_plans">
                        {floorPlanStage.map((item, index) => {
                            const className = activeFloorPlanStageIndex === index ? "current" : "";
                            const inputClassName = index === activeIndex ? "active" : "";
                            return (
                                <button
                                    key={index}
                                    onClick={() => updateActiveFloorPlanStageId(item.id)}
                                    className={className}
                                    onDoubleClick={() => setActiveIndex(index)}>
                                    <SquareDashedIcon />{" "}
                                    {index === activeIndex ? (
                                        <input
                                            value={item.floor_plan_name ? item.floor_plan_name : "New Floor Plan"}
                                            placeholder="New Floor Plan"
                                            className={inputClassName}
                                            ref={ref}
                                            onChange={renameFloorPlanHandler}
                                        />
                                    ) : (
                                        <span> {item.floor_plan_name ? item.floor_plan_name : "New Floor Plan"} </span>
                                    )}
                                </button>
                            );
                        })}
                    </div>
                    <button onClick={addNewFloorPlanHandler}>
                        <SquareDashedIcon /> Add new
                    </button>
                </div>
                <div className="right_button_group">
                    <SiteDropdown title={<ThreeDotsIcon />} className="custom three_dot no_hover_tick">
                        <Dropdown.Item onClick={exportFloorPlanAsPDF}>Download Floor Plan • (PDF)</Dropdown.Item>
                        <Dropdown.Item onClick={onDeleteFloorPlanHandler}>Delete Floor plan</Dropdown.Item>
                    </SiteDropdown>
                </div>
            </div>
            <div className="floor_plan_view">
                {/* <>
                    {floorPlanStage?.length > 0 && (
                        <div className="floor_plan_top_bar">
                            <div className="title">
                                <SquareDashedIcon />
                                <input
                                    value={currentFloorPlan[0]?.floor_plan_name ?? ""}
                                    placeholder="New Floor Plan"
                                    onChange={renameFloorPlanHandler}
                                />
                            </div>
                            <div className="floor_plan_button_group">
                                <hr />
                                <div className="right_button_group">
                                    <SiteDropdown title={<ThreeDotsIcon />} className="custom three_dot no_hover_tick">
                                        <Dropdown.Item onClick={onDeleteFloorPlanHandler}>Delete Floor plan</Dropdown.Item>
                                    </SiteDropdown>
                                </div>
                            </div>
                        </div>
                    )}
                </> */}
                {floorPlanStage?.length === 0 ? (
                    <div className="floor_plan_canvas_view">
                        <div className="no_floor_plan_card">
                            <div className="image_wrapper">
                                <SquareDashedIcon />
                            </div>
                            <div className="floor_plan_text">
                                <h4>You have no Floor Plan yet :(</h4>
                                <p>Draw your floor plan from scratch using our custom objects and elements and your creativity.</p>
                            </div>
                            <div className="button_group">
                                {/* <button className="btn_bordered">Request a Plan</button> */}
                                <button className="btn_red" onClick={addNewFloorPlanHandler}>
									Create Floor Plan
                                    <PlusIcon fill="#fff" />
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="canvas__view">
                        <div className="canvas_wrapper" style={{ height: "unset" }}>
                            <div className="canvas_board" style={{ height: "unset", width: "100%" }}>
                                <div className="draw_actions_top"></div>
                                <div style={style}>
                                    <div style={canvasStyle}>
                                        <Tldraw
                                            persistenceKey={`floor_plan_${activeFloorPlanStageId}`}
                                            shapeUtils={customShapes}
                                            onMount={updateEditorOnMount}
                                            options={{ maxPages: 1, defaultSvgPadding: 20 }}
                                        />
                                    </div>
                                </div>
                                <div className="draw_actions_bottom">
                                    <div className="draw_button_wrapper">
                                        <button onClick={() => fullViewHandler(true)}>
                                            <ExpandIcon />
                                        </button>
                                        <button onClick={() => fullViewHandler(false)}>
                                            <ShrinkIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pick_elements">
                            <div className="pick_elements_top_bar">
                                <div className="top_bar_header">
                                    {fillSpaceElements.map((item) => {
                                        const className = item.type === pickElementStage ? "active" : "";
                                        return (
                                            <button
                                                key={item.type}
                                                className={`pick_element ${className}`}
                                                onClick={() => setPickElementStage(item.type)}>
                                                {item.icon} <span>{item.text}</span>
                                            </button>
                                        );
                                    })}
                                </div>
                                <div className="right_view">
                                    <div className="search_tools_element">
                                        <Search fill="#D9D9D9" /> <input placeholder="Search" value={searchText} onChange={searchTextHandler} />
                                        {isSearching && (
                                            <div className="spinner_element_wrapper">
                                                <Spinner className="mx-0" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="elements_group">
                                {searchText ? (
                                    searchResult.length > 0 ? (
                                        searchResult.map((item, index) => (
                                            <button key={index} onClick={() => addFloorplanElement(item?.element_id as string)}>
                                                {item.icon}
                                            </button>
                                        ))
                                    ) : (
                                        <p className="no_element">No element for this search query</p>
                                    )
                                ) : (
                                    <>
                                        {pickElementStage === "table_chairs__category" ? (
                                            <div className="table_chairs_elements">
                                                <FloorPlanElement elementGroup="chairs" addFloorPlanElementHandler={addFloorplanElement} />
                                                <FloorPlanElement elementGroup="square_tables" addFloorPlanElementHandler={addFloorplanElement} />
                                                <FloorPlanElement elementGroup="round_tables" addFloorPlanElementHandler={addFloorplanElement} />
                                                <FloorPlanElement elementGroup="rectangle_tables" addFloorPlanElementHandler={addFloorplanElement} />
                                            </div>
                                        ) : (
                                            floorPlanGroupElements.map((item, index) => {
                                                return (
                                                    <button key={index} onClick={() => addFloorplanElement(item.element_id)}>
                                                        {item.icon}
                                                    </button>
                                                );
                                            })
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
}

export default memo(FloorplanTLDrawCanvas);
